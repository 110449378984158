export const createCourseItemFields = [
  {
    name: 'description_ru',
    type: 'Textarea',
    label: 'on_ru',
    particle: 'ru',
  },
  {
    name: 'description_en',
    type: 'Textarea',
    label: 'on_en',
    particle: 'en',
  },
  {
    name: 'description_ky',
    type: 'Textarea',
    label: 'on_ky',
    particle: 'ky',
  },
];

export const createCourseItemFieldsNames = [
  { name: 'name_ru', type: 'text', label: 'on_ru', particle: 'ru' },
  { name: 'name_en', type: 'text', label: 'on_en', particle: 'en' },
  { name: 'name_ky', type: 'text', label: 'on_ky', particle: 'ky' },
];

export const createCourseItemImages = [
  { name: 'image_ru', label: 'on_ru', particle: 'ru' },
  { name: 'image_ky', label: 'on_ky', particle: 'ky' },
  { name: 'image_en', label: 'on_en', particle: 'en' },
];
