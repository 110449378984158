// External dependencies
import { Div, Icon, Row, Text } from 'atomize';
import { navigate } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '@app/redux/store';
import Header from '@shared/ui/admin/header/Header';
import Button from '@shared/ui/button';
import FormField from '@shared/ui/form/FormField';
import { defaultInputStyle } from '@shared/ui/form/FormStyles';
import ErrorPopup from '@shared/ui/popup/ErrorPopup';
import SuccessPopup from '@shared/ui/popup/SuccessPopup';
import ImageUploader from '../createCourse/ImageUploader';
import VideoInput from '../createCourse/VideoUploader';
import {
  createCourseItemFields,
  createCourseItemFieldsNames,
  createCourseItemImages,
} from './models';
import {
  createCourseItemAddValue,
  createCourseItemRequest,
  createCourseItemReset,
  createCourseItemResetError,
} from './redux/actions';

export default function CreateCourseItem() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const courseId = localStorage.getItem('courseId') as string;

  const {
    courseItem,
    error,
    isFormChanged,
    isSuccess,
    loading,
    imageRuError,
    imageKyError,
    imageEnError,
    nameRuError,
    nameKyError,
    nameEnError,
    videoUrlError,
  } = useSelector((state: RootState) => state.createCourseItem);
  const {
    item: { description, duration, title, previewImage, url },
    parentId,
  } = courseItem;

  const errorMessage = error?.code ? (
    <Trans>{error?.code}</Trans>
  ) : (
    error?.message
  );

  const imageErrors = {
    ru: imageRuError,
    ky: imageKyError,
    en: imageEnError,
  };

  const namesErrors = {
    ru: nameRuError,
    ky: nameKyError,
    en: nameEnError,
  };

  function renderFormFields(fieldList: any[], parent: any, errors?: any) {
    const styles = {
      ...defaultInputStyle,
      fontFamily: 'Open Sans',
    };

    return fieldList.map((field) => (
      <Row key={field.name} w="100%">
        <FormField
          bg="basic_border_color"
          inputName={field.name}
          inputType={field.type}
          label={t(field.label)}
          inputValue={parent[field?.particle]}
          onChange={onInputChange}
          inputStyle={field.type === 'Textarea' ? styles : defaultInputStyle}
          error={errors && errors[field?.particle]}
        />
      </Row>
    ));
  }

  function renderImageUploaders(imageList: any[]) {
    return imageList.map((field, index) => (
      <Div key={index} w="100%" h="250px">
        <ImageUploader
          label={t(field.label)}
          name={field.name}
          onChange={onUploadImage}
          value={previewImage[field?.particle]}
          error={imageErrors[field?.particle]}
        />
      </Div>
    ));
  }

  function onInputChange(e) {
    const { name, value } = e.target;

    dispatch(createCourseItemAddValue({ [name]: value }));
  }

  async function onUploadImage(name: string, event) {
    // const url = await uploadVideo(event); // this function is not ready yet
    const url =
      'https://www.rightstufanime.com/images/productImages/9781974740932_specialtybook-naruto-shippuden-the-official-coloring-book-primary.jpg?resizeid=3&resizeh=600&resizew=600';
    dispatch(createCourseItemAddValue({ [name]: url }));
  }

  async function onUploadVideo(name, event: React.ChangeEvent) {
    // const url = await uploadVideo(event); // this function is not ready yet
    const url = 'https://www.youtube.com/watch?v=SSzegrjvxX4';

    dispatch(createCourseItemAddValue({ [name]: url }));
  }

  function continueOperation() {
    const emptyFieldsArray = checkTheFields();

    if (emptyFieldsArray.length) {
      return;
    }

    dispatch(createCourseItemRequest(courseItem));
  }

  function resetSuccess() {
    dispatch(createCourseItemReset());
  }

  function resetError() {
    dispatch(createCourseItemResetError());
  }

  function navigateBack() {
    navigate(-1);
  }

  function checkTheFields() {
    const result = [];

    if (!url) {
      dispatch(createCourseItemAddValue({ videoUrl: url }));
      result.push('videoUrl');
    }

    const emptyTitleFields = iterateAndValidateObj(
      createCourseItemFieldsNames,
      title,
    );
    const emptyImageFields = iterateAndValidateObj(
      createCourseItemImages,
      previewImage,
    );

    return [...result, ...emptyTitleFields, ...emptyImageFields];
  }

  function iterateAndValidateObj(arrayOfFields: any, values: any) {
    const emptyFieldsArray = [];

    for (let field of arrayOfFields) {
      const label = field?.name;
      const value = values[field?.particle];

      if (!value) {
        dispatch(createCourseItemAddValue({ [label]: value }));

        emptyFieldsArray.push(label);
      }
    }
    return emptyFieldsArray;
  }

  useEffect(() => {
    if (courseId && !parentId) {
      dispatch(createCourseItemAddValue({ parentId: courseId }));
    }

    return () => {
      dispatch(createCourseItemReset());
    };
  }, []);

  return (
    <Div className="container" m="0 auto" p="50px 0">
      <Header />
      <Div p={{ t: '80px' }}>
        <Div
          d="flex"
          align="center"
          cursor="pointer"
          p="20px 0"
          onClick={navigateBack}
        >
          <Icon name="LeftArrow" size="50px" />
          <Text textSize="subtitle" textWeight="600">
            {t('back')}
          </Text>
        </Div>
        <Div>
          <Text textWeight="600" textSize="heading" p={{ t: '30px' }}>
            <Trans>lessonTitles</Trans>
          </Text>
          <Div
            p="20px 30px"
            d="flex"
            flexDir={{ xs: 'column', lg: 'row' }}
            style={{ gap: '40px' }}
          >
            {renderFormFields(createCourseItemFieldsNames, title, namesErrors)}
          </Div>
        </Div>
        <Div>
          <Text textWeight="600" textSize="heading">
            <Trans>description</Trans>
          </Text>
          <Div
            p="20px 30px"
            d="flex"
            flexDir={{ xs: 'column', lg: 'row' }}
            style={{ gap: '40px' }}
          >
            {renderFormFields(createCourseItemFields, description)}
          </Div>
        </Div>
        <Div d="flex" p="0 30px" style={{ gap: '40px' }}>
          <Row style={{ whiteSpace: 'nowrap' }} w={{ xs: '100%', lg: '34%' }}>
            <FormField
              bg="basic_border_color"
              inputType="number"
              label={t('duration')}
              inputName="duration"
              inputValue={duration}
              onChange={onInputChange}
            />
          </Row>
        </Div>
        <Div m={{ t: '40px' }}>
          <Text textWeight="600" textSize="heading">
            <Trans>Video</Trans>
          </Text>
          <Div d="flex" flexDir="column" p="20px 20px">
            <VideoInput
              label="url"
              onChange={onUploadVideo}
              error={videoUrlError}
              name="videoUrl"
            />
          </Div>
        </Div>
        <Div>
          <Text textWeight="600" textSize="heading" m={{ t: '40px' }}>
            <Trans>image</Trans>
          </Text>
          <Div
            d="flex"
            flexDir={{ xs: 'column', lg: 'row' }}
            style={{ gap: '40px' }}
            p="20px 20px"
          >
            {renderImageUploaders(createCourseItemImages)}
          </Div>
        </Div>
        <Div
          p="50px 20px"
          maxW="600px"
          d="flex"
          w="100%"
          style={{ gap: '20px' }}
        >
          <Button
            typeColor="primary"
            onClick={continueOperation}
            disabled={!isFormChanged}
            loading={loading}
          >
            {t('create')}
          </Button>
          <Button typeColor="secondary" onClick={navigateBack}>
            {t('cancel')}
          </Button>
        </Div>
      </Div>
      {isSuccess && (
        <SuccessPopup
          onSubmit={resetSuccess}
          submitText="close"
          title="newCourseItemSuccess"
        >
          <Div>
            <Text textColor="primary"></Text>
            <Text m={{ t: '15px' }} textColor="primary">
              <Trans>title</Trans> <Trans>on_ru</Trans> - {title?.ru}
            </Text>
            <Text m={{ t: '15px' }} textColor="primary">
              <Trans>title</Trans> <Trans>on_ky</Trans> - {title?.ky}
            </Text>
            <Text m={{ t: '15px' }} textColor="primary">
              <Trans>title</Trans> <Trans>on_en</Trans> - {title?.en}
            </Text>
          </Div>
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup
          onSubmit={resetError}
          submitText="close"
          title="errorOccurred"
        >
          <Text>{errorMessage}</Text>
        </ErrorPopup>
      )}
    </Div>
  );
}
