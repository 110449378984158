// Externall dependencies
import { graphql } from 'gatsby';
import React from 'react';

// Local dependencies
import CreateCourseItem from '@components/courses/createCourseItem/CreateCourseItem';
import UserLoggedIn from '@feautures/ui/userLoggedIn/UserLoggedIn';
import Layout from '@shared/ui/layout/WebLayout';
import { pagePermissions } from '../../../../PagePermissions';

export default function index() {
  return (
    <Layout>
      <UserLoggedIn roles={pagePermissions.createCourseItem}>
        <CreateCourseItem />
      </UserLoggedIn>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
